/*global jQuery*/
/**
 * {{name}}
 * Global JS
 *
 * version: {{version}}
 * file:    {{file}}
 * author:  Squiz Australia
 * change log:
 *     {{date}} - First revision
 */

/*
 * Table of Contents
 *
 * - Global
 * - Modules
{{toc}}
 */
'use strict';
/*
--------------------
Global
--------------------
*/
//  Declare JS Enabled.

/*
--------------------
Modules
--------------------
*/

$.validator.addMethod("check_date_of_birth", function(value, element) {
    
    var day = $("#q1830_q3_value_d").val();
    var month = $("#q1830_q3_value_m").val();
    var year = $("#q1830_q3_value_y").val();
    var age =  18;

    var mydate = new Date();
    mydate.setFullYear(year, month-1, day);

    var currdate = new Date();
    currdate.setFullYear(currdate.getFullYear() - age);

    return currdate > mydate;

}, "You must be at least 18 years of age.");

$("form").validate({
    rules: {
        "q1830_q3_value[y]": { check_date_of_birth: true }
    }
});

// $("form").validate();

// $( "#dob_year" ).rules( "add", {
//     check_date_of_birth : true
// });